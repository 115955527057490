import { extend } from 'lodash';
import React, { useEffect, useState } from 'react';
import './EducationSikkim.css';
import { Badge, ListGroup } from 'react-bootstrap';
import moment from 'moment';
import { getDistList } from '../services/api';

function EducationSikkim({ summery, state, dist, setNewDist, setDistData }) {
  const [distList, setDistList] = useState([]);

  useEffect(() => {
    getDistList(state).then((res) => {
      setDistList(res.data.data);
    });
  }, []);

  return (
    <div className='edutabel'>
      <div className='left'>
        <div className='l-sec'>
          <div className='l-sec-l'>
            <h5 className='fw-bold'>Education Department Of {state}</h5>
          </div>
          <div className='l-sec-r'>
            <div>
              <div id='select-box'>
                <select
                  class='form-select'
                  aria-label='Default select example'
                  value={dist}
                  onChange={(e) => {
                    setNewDist(e.target.value);
                    setDistData(undefined);
                  }}
                >
                  {distList?.map((item) => (
                    <option value={item.district}>{item.district}</option>
                  ))}

                  {/* <option value="1">Option 1</option> */}
                </select>
              </div>
              <ListGroup className='text-start border-0'>
                <ListGroup.Item>
                  No. of School<span>{summery?.no_school}</span>
                </ListGroup.Item>
                <ListGroup.Item>
                  No. of Students<span>{summery?.no_user}</span>
                </ListGroup.Item>
                <ListGroup.Item>
                  Date of Reports<span>{moment().format('DD/MM/yyyy')}</span>
                </ListGroup.Item>
              </ListGroup>
            </div>
            <div></div>
          </div>
        </div>
      </div>
      <div className='right'>
        <div>
          <h6 className='fw-bold'>Score Referance Table</h6>
          <ul>
            <li className='text-warning'>0-40 Aspiring</li>
            <li className='text-danger'>41-80 Scholar</li>
            <li className='text-primary'>81-100 Master</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default EducationSikkim;

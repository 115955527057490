import * as mod from './../resources/url';
import React from 'react';

import axios from 'axios';

function createform() {
  return (
    <div>
      <NameForm />
    </div>
  );
}

class NameForm extends React.Component {
  state = { email: '', mobile: '' };

  constructor(props) {
    super(props);

    this.handleEmail = this.handleEmail.bind(this);
    this.handleMobile = this.handleMobile.bind(this);

    //this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
  }

  handleEmail(event) {
    //var val = Number(event.value) - 1 ;
    //console.log(val);
    console.log(event);
    this.setState({ email: event.target.value });
    console.log(this.state.typeselect);
    //fetchData();
    //this.setState({financialGoal: event.target.value.replace(/\D/,'')}
  }

  handleMobile(event) {
    //var val = Number(event.value) - 1 ;
    //console.log(val);
    console.log(event);
    this.setState({ mobile: event.target.value });
  }

  handleSubmitForm() {
    console.log('submit Clicked');

    var email = this.state.email;
    var mobile = this.state.mobile;
    function validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
    function phonenumber(inputtxt) {
      /*
            var phoneno = /^\d{10}$/;
            if((inputtxt.value.match(phoneno))){
                return true;
            }
            */
      var phoneNum = inputtxt.replace(/[^\d]/g, '');
      if (phoneNum.length == 10) {
        return true;
      }
    }
    //alert(comment);
    var emailok = 0;
    var mobok = 0;
    var errmsg = '';
    if (validateEmail(email)) {
      emailok = 1;
    } else {
      errmsg += 'Please Enter a Valid Email\n';
    }
    if (phonenumber(mobile)) {
      mobok = 1;
    } else {
      errmsg += 'Please Enter a Valid Mobile Number';
    }

    if (emailok == 1 && mobok == 1) {
      var apiBaseUrl = mod.api_url + '/user/freetrial';

      var data = {
        mobileNumber: mobile,
        countryCode: '+91',
        emailId: email,
      };
      var headers = {
        'Access-Control-Allow-Origin': 'POST',
      };

      console.log(data);

      axios
        .post(apiBaseUrl, data)
        .then(function (response) {
          console.log(response);

          if (response.status == 200) {
            alert(
              'Thank you for requesting the  free trial of Neeve. Our support team will contact you shortly.'
            );
            window.location.href = '/';
            //window.location.href = `/licence_key_verificaton?udise=${data.udiseId}`;

            //console.log("Login1 successfull");
          } else {
            alert(response.data.message);
          }
        })
        .catch(function (error) {
          console.log(error);
          alert('Unable to Post comment');
        });
    } else {
      alert(errmsg);
    }

    //console.log(typeselect, subjectselect, classselect, comment);
  }

  render() {
    return (
      <form>
        <div className='row'>
          <div className='col' style={{ textAlign: 'right' }}>
            <input
              className='input-text-box'
              type='text'
              placeholder='Email Id'
              onChange={this.handleEmail}
            />
          </div>
          <div className='col' style={{ textAlign: 'left' }}>
            <input
              className='input-text-box'
              type='text'
              placeholder='Mobile Number'
              onChange={this.handleMobile}
            />
          </div>
        </div>
        <br />
        <div className='row'>
          <div className='col'>
            <input
              className='btn header-nav-btn-primary'
              type='button'
              value='START FREE TRIAL'
              onClick={this.handleSubmitForm}
            />
          </div>
        </div>
      </form>
    );
  }
}

export default createform;

import Header from '../header-signedin';
import Carousel from 'react-elastic-carousel';
import { Card } from 'react-bootstrap';
// import * as mod from './../../resources/url';
// import { useEffect, useState } from "react";
// import axios from "axios";
// import { api_url } from "../../resources/url";
// import * as _ from "lodash";

/* import { Link } from "react-router-dom";
import debounce from "lodash.debounce";
import LeftMenu from "../left-menu"; */

import { Redirect } from 'react-router';
import { useEffect, useState } from 'react';
import { getSchoolDtlByUdiseId, getAllCourse } from '../../services/api';
import './training-style.css';
import CourseList from './course-list';

function NeeveTraining() {
  const udise_id = localStorage.getItem('udise_id');
  const [school, setSchool] = useState([]);
  const [allCourse, setCourse] = useState([]);

  useEffect(() => {
    if (!localStorage.getItem('is_done')) {
      return <Redirect to={'/login'} />;
    }

    getSchoolDtlByUdiseId(udise_id).then(
      (res) => {
        setSchool(res.data.data);
      },
      (error) => {
        console.log(
          '================ error on getSchoolDtlByUdiseId ==============',
          error
        );
      }
    );

    getAllCourse().then(
      (res) => {
        setCourse(res.data.data);
      },
      (error) => {
        console.log(
          '================ error on getAllCourse ==============',
          error
        );
      }
    );
  }, [udise_id, setSchool, setCourse]);

  const certList = [
    {
      img: require('./../../assets/certificate.png'),
      title: '',
      subheading: '',
      text: '  ',
    },
    {
      img: require('./../../assets/certificate2.png'),
      title: '',
      subheading: '',
      text: '  ',
    },
    {
      img: require('./../../assets/certificate3.png'),
      title: '',
      subheading: '',
      text: '  ',
    },
    {
      img: require('./../../assets/certificate4.png'),
      title: '',
      subheading: '',
      text: '  ',
    },
  ];

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 3 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 },
  ];

  const renderCard = (card, index) => {
    return (
      <Card key={index} className='item'>
        <Card.Img variant='top' src={card.img} />
        <Card.Body className='text-dark'>
          <Card.Title>{card.title}</Card.Title>
          <Card.Subtitle>{card.subheading}</Card.Subtitle>
          <Card.Text>{card.text} </Card.Text>
        </Card.Body>
      </Card>
    );
  };

  return (
    <div>
      <Header />

      <section className='topSec'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12 nTop'>
              <h2>Neeve Training & Development Centre</h2>
              <p className='text-center whiteText headinSub'>
                With the aim of providing quality education, LEP has gone online
                starting 2021. Students and teachers work on digital resources,
                assessments are conducted...{' '}
              </p>
              <hr className='hrCus' />

              <div className='schoolDetails'>
                <div className='ban'>
                  <div className='banCon'>
                    <ul className='list-inline mb-3'>
                      <li className='list-inline-item '>
                        <strong className=''>{school.school_name}</strong>{' '}
                      </li>
                      <li className='list-inline-item '>
                        <span>UDISE</span> - {school.udise_code}{' '}
                      </li>
                      <li className='list-inline-item '>
                        <span>State</span> - {school.state}{' '}
                      </li>
                      <li className='list-inline-item'>
                        <span>District</span> - {school.district}{' '}
                      </li>
                      <li className='list-inline-item '>
                        <span>Enrolment</span> - {school.no_student}{' '}
                      </li>
                      <li className='list-inline-item '>
                        <span>ClassNamees</span> : 6 - 10{' '}
                      </li>
                    </ul>
                    <a href='{{1}}' className='sbutton mb-3'>
                      Update School Info
                    </a>
                  </div>
                  <img src={require('./../../assets/books.jpg')} alt='' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='secGap'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 tex-center'>
              <h6 className='blue-text'>Training & Development</h6>
              <h2 className='assText '>Neeve List of Courses</h2>
              <hr />
            </div>

            <div className='col-md-12'>
              <div className='w-90'>
                <CourseList courseList={allCourse} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='whiteSec'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='col-12 tex-center'>
                <h6 className='blue-text'>Courses</h6>
                <h2 className='assText '>Neeve on going Courses</h2>
              </div>

              <div className='w-90'>
                <div className='tabel-responsive'>
                  <table className='table table-bordered whiteTabel table-bordered '>
                    <thead>
                      <tr>
                        <th scope='col'>Course Title</th>
                        <th scope='col' className='tex-center'>
                          Completed
                        </th>
                        <th scope='col' className='tex-center'>
                          Ongoing
                        </th>
                        <th scope='col'> </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td scope='row'>Technology in Education</td>
                        <td className='tex-center'>4</td>
                        <td className='tex-center'>1</td>
                        <td className='tex-center'>
                          <a href='{{1}}' className='skyButton'>
                            Go to course
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td scope='row'>Technology in Education</td>
                        <td className='tex-center'>1</td>
                        <td className='tex-center'>2</td>
                        <td className='tex-center'>
                          <a href='{{1}}' className='skyButton'>
                            Go to course
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='secGap'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='col-12 tex-center'>
                <h6 className='blue-text'>Training & Development</h6>
                <h2 className='assText '>Course Marks Analytics </h2>
                <hr />
              </div>
              <div className='w-90'>
                <div className='chartCon'>
                  <img src={require('./../../assets/t_1.jpg')} alt='' />
                  <img src={require('./../../assets/t_2.jpg')} alt='' />
                  <img src={require('./../../assets/t_3.jpg')} alt='' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='skySec'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <h6 className='blue-text'>Training & Development</h6>
              <h2 className='whiteText '>Our Latest Certificates </h2>
              <p className='text-center whiteText'>
                3 latest certificates are shown here. Rest are available in the
                individual trainees' accounts{' '}
              </p>
              <hr />
              <div className='w-90'>
                <div className='certificateImg owl-carousel1 owl-theme '>
                  <Carousel
                    itemPadding={[0, 8]}
                    itemsToScroll={1}
                    showArrows={false}
                    breakPoints={breakPoints}
                  >
                    {certList.map(renderCard)}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default NeeveTraining;

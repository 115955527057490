import React from 'react';
import { useLocation } from 'react-router-dom';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import PieChart from './PieChart';
import Header from './header-signedin';
import { useEffect, useState } from 'react';
import * as _ from 'lodash';
import sklogo from './../assets/ML_logo.jpeg';
import neveBg from './../assets/neve-highlight-bg.jpg';
import aspBg from './../assets/aspring-bg.png';
import LeftMenu from './left-menu';
import { Redirect, useParams } from 'react-router';
import Heading from './Heading';
import HeadingWhite from './HeadingWhite';
// import CceMTabel from "./CceMTabel";
import CceTabel from './CceTabel';

import { Button } from 'react-bootstrap';
import PerformingCardSliderM from './PerformingCardSliderM';
import TeacherStudentTestimonialM from './TeacherStudentTestimonialM';
import DocumentsReports from './DocumentsReports';
import { getDistDashboardData, getStateDashboardData } from '../services/api';
import Loader from './loader';

function Megha() {
  console.log(localStorage.getItem('role'));

  const { state, district } = useParams();
  const [distData, setDistData] = useState();

  useEffect(() => {
    getDistDashboardData(state, district).then((res) => {
      setDistData(res.data.data);
    });
  }, [district]);

  console.log(district);

  const [stateData, setStateData] = useState();
  const [pieDataEnglish, setPieDataEnglish] = useState();
  const [pieDataMaths, setPieDataMaths] = useState();
  const [pieDataScience, setPieDataScience] = useState();
  useEffect(() => {
    getStateDashboardData(state).then((res) => {
      setStateData(res.data.data);

      const opData = res.data?.data?.dashboard_state_cce_report;
      const _pieDataEnglish = opData?.filter(
        (item) => item.subject === 'English'
      );
      const _pieDataMaths = opData?.filter((item) => item.subject === 'Maths');
      const _pieDataScience = opData?.filter(
        (item) => item.subject === 'Science'
      );
      setPieDataEnglish(_pieDataEnglish);
      setPieDataMaths(_pieDataMaths);
      setPieDataScience(_pieDataScience);
    });
  }, []);
  const getDivHeight = (id) => {
    let innerHeight = 0;
    let element = window; //document.getElementById(id);
    if (element !== null) {
      innerHeight = element.clientHeight;
    }
    return innerHeight;
  };

  return (
    <>
      <Header />
      <div className='container-fluid p-10 bg-grey' id='stateDashboard'>
        {stateData && (
          <div className='row min-height' style={{ padding: 20 }}>
            <div className=' col-md-3 left-menu-mod'>
              <br />
              <LeftMenu />
            </div>
            <div className=' col-md-9 '>
              <div className='row'>
                <div className='col-12 right-menu '>
                  <div className='row'>
                    <div className='col-12 right-menu-full-width-mod '>
                      <div className='row'>
                        <div className='col-12  '>
                          <div className='skycruv '>
                            <h2 className='text-center text-white pt-3 pb-3'>
                              Neeve State Dashboard - {state}
                            </h2>
                          </div>
                          <div className='position-relative  w-100 text-center p-3 '>
                            <div className='position-absolute sk-z'>
                              <div className=' d-flex  justify-content-center p-2'>
                                <img
                                  src={sklogo}
                                  style={{
                                    width: '200px',
                                    borderRadius: '100%',
                                  }}
                                />
                              </div>{' '}
                            </div>
                          </div>
                          <div className=' d-flex  justify-content-center p-3 '>
                            <div className='d-inline-flex  w-90 mr-auto ml-auto flex-column   '>
                              <p className='text-center p-3'>
                                With the aim of providing quality education, LEP
                                has gone online starting 2021. Students and
                                teachers work on digital resources, assessments
                                are conducted, checked and analysed digitally.
                                Smart EdTech platform Neeve connects students,
                                teachers, schools and administrators online
                                through a flowing stream of digital information
                                to deliver world class eduction to a large
                                number of students.
                              </p>
                              <p className='text-center'>
                                This dashboard provides macro and micro
                                analytics of data in real time. Designed for the
                                state administrators, all necessary data,
                                analyses and reports are available here -
                                downloadable with one click. Explore the Neeve
                                State Level Dashboard for a birds eye view of
                                LEP.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-12'>
                          <div className='d-flex flex-column sec-pad-top '>
                            <div className='w-100 text-center pt-5 '>
                              <Heading text='Neeve Highlights' />
                            </div>
                            <div className='blank'></div>
                            <div className='neve-highlight tree'>
                              <div className='neve-bg neve-none'>
                                <img src={neveBg} />
                              </div>
                              {stateData?.dashboard_highlights && (
                                <div className='d-flex view-responsive neve-highlight '>
                                  <div className='mt-2-sm'>
                                    <div className='circle cir-sky'>
                                      <p className='cir-text'>
                                        <span className='bigText'>
                                          {
                                            stateData?.dashboard_highlights[0]
                                              .text
                                          }
                                        </span>{' '}
                                        Schools
                                      </p>
                                    </div>
                                  </div>

                                  <div className='mt-2-sm'>
                                    <div className='circle cir-indigo'>
                                      <p className='cir-text'>
                                        <span className='bigText'>
                                          {
                                            stateData?.dashboard_highlights[1]
                                              .text
                                          }
                                        </span>{' '}
                                        Students
                                      </p>
                                    </div>
                                  </div>

                                  <div className='mt-2-sm'>
                                    <div className='circle cir-war'>
                                      <p className='cir-text'>
                                        <span className='bigText'>
                                          {
                                            stateData?.dashboard_highlights[2]
                                              .text
                                          }
                                        </span>{' '}
                                        Online
                                      </p>
                                    </div>
                                  </div>

                                  <div className='mt-2-sm'>
                                    <div className='circle cir-pink'>
                                      <p className='cir-text2'>
                                        <span className='bigText'>
                                          {
                                            stateData?.dashboard_highlights[3]
                                              .text
                                          }
                                        </span>{' '}
                                        Question answered
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-12'>
                          <div className='d-flex flex-column sec-pad-top'>
                            <div className='w-100 text-center pt-5 '>
                              <Heading text='Realtime CCE Report of the State' />

                              <div className='sub-head pt-5 pb-5'>
                                <p className=' text-center'>
                                  Students work on app Neeve that has over 1000
                                  resources adapted to individual students
                                  learning level. Continuous and Comprehensive
                                  Evaluation (CCE) is going on as the students
                                  work on app Neeve, but in a stress free and
                                  fun environment. Each students activity on
                                  Neeve is captured and aggregated to make
                                  class, school and state level reports.
                                  Individual students and class reports are
                                  accessible by the teacher. State, District and
                                  School level aggregates are available on this
                                  dashboard.
                                </p>
                              </div>
                            </div>

                            <div className='d-block neve-highlight '>
                              <PieChart
                                pieDataEnglish={pieDataEnglish}
                                pieDataMaths={pieDataMaths}
                                pieDataScience={pieDataScience}
                              />
                            </div>
                          </div>
                        </div>

                        <div className='col-12 '>
                          <div className='sky-curve-both'>
                            <div className=' mb-5'>
                              <div className=' mb-5 text-center '>
                                <div className='pad-top text-white'>
                                  <HeadingWhite
                                    className='text-white'
                                    text='Real-time CCE Report - For Districts'
                                  />
                                </div>
                                <div className='d-flex justify-content-center  '>
                                  <div className=' w-95  '>
                                    <CceTabel
                                      cceReport={
                                        stateData.rt_cce_report_district
                                      }
                                    />

                                    <div>
                                      <Button
                                        variant='warning'
                                        className='rounded-btn fw-bold d-none'
                                      >
                                        Show More
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='mt-5 mb-5 '>
                              <div className='mt-5 mb-5 text-center '>
                                <div className=' '>
                                  {/* <HeadingWhite
                                        className="text-white"
                                        text="Highlights from State"
                                      /> */}
                                </div>
                                <div className='d-flex justify-content-center  '>
                                  <div className=' w-95  '>
                                    <div className='pt-5 text-white'>
                                      {/* <PerformingCardSliderM /> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='row'>
                              <div className='col-12'>
                                <div className='d-flex flex-column  '>
                                  <div className='w-100 text-center '>
                                    <div className='pad-top text-white'>
                                      <HeadingWhite
                                        className='text-white'
                                        text={`Aspiring District - ${stateData?.dashboard_focus_district[0].district}`}
                                      />
                                    </div>
                                  </div>

                                  <div className='aspring'>
                                    <div className='aspring_bg'></div>
                                    <div className='aspImg'>
                                      <img src={aspBg} />
                                    </div>
                                    <div className='d-flex view-responsive neve-highlight '>
                                      <div className='mt-2-sm'>
                                        <div className='circle cir-sea-green'>
                                          <p className='cir-text'>
                                            <span className='bigText'>
                                              {
                                                stateData
                                                  ?.dashboard_focus_district[0]
                                                  .text
                                              }{' '}
                                            </span>
                                            Schools
                                          </p>
                                        </div>
                                      </div>

                                      <div className='mt-2-sm'>
                                        <div className='circle cir-sea-violet'>
                                          <p className='cir-text'>
                                            <span className='bigText'>
                                              {
                                                stateData
                                                  ?.dashboard_focus_district[1]
                                                  .text
                                              }
                                            </span>{' '}
                                            Students
                                          </p>
                                        </div>
                                      </div>

                                      <div className='mt-2-sm'>
                                        <div className='circle cir-war'>
                                          <p className='cir-text'>
                                            <span className='bigText'>
                                              {
                                                stateData
                                                  ?.dashboard_focus_district[2]
                                                  .text
                                              }
                                            </span>
                                            Online
                                          </p>
                                        </div>
                                      </div>

                                      <div className='mt-2-sm'>
                                        <div className='circle cir-pink'>
                                          <p className='cir-text2'>
                                            <span className='bigText'>
                                              {
                                                stateData
                                                  ?.dashboard_focus_district[3]
                                                  .text
                                              }
                                            </span>{' '}
                                            Question answered
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='mt-5 mb-5 '>
                              <div className='mt-5 mb-5 text-center '>
                                <div className='pad-top pt-5'>
                                  <HeadingWhite
                                    className='text-white'
                                    text='Teachers’ & Students’ Testimonials'
                                  />
                                </div>
                                <div className='d-flex justify-content-center  '>
                                  <div className=' w-95  '>
                                    <div className='pt-5 text-white'>
                                      <TeacherStudentTestimonialM />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='col-12'>
                          <div className=' mb-5 '>
                            <div className=' mb-5 text-center '>
                              <div className=' '>
                                <Heading
                                  className='text-indigo'
                                  text='Documents and Reports'
                                />
                              </div>
                              <div className='d-flex justify-content-center  '>
                                <div className=' w-95  '>
                                  <div className='pt-5 text-white'>
                                    <DocumentsReports
                                      DocuReport={
                                        stateData.dashboard_document_and_reports
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {!stateData && (
          <Loader
            height={getDivHeight('stateDashboard') - 162}
            padding={20}
            backgroundColor={'#FFFFFF'}
          />
        )}
      </div>
    </>
  );
}

export default Megha;

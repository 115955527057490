import Header from './header-signedin';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { api_url } from '../resources/url';
import * as _ from 'lodash';
import { Link } from 'react-router-dom';
import debounce from 'lodash.debounce';

import performance_logo from './../assets/noun_Graph_1928752.svg';
import resource_logo from './../assets/noun_resources_3870957.svg';
import forum_logo from './../assets/noun_forums_1870692.svg';
import video_logo from './../assets/1159798.svg';
import Vimeo from '@u-wave/react-vimeo';
import Pagination from 'react-js-pagination';

import LeftMenu from './left-menu';
import { Redirect } from 'react-router';

var timeout = 1000;
// let page = 1;

function Resource() {
  const [classId, setClassId] = useState('10');
  const [subject, setSubject] = useState('');
  const [resources, setResources] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [scrollFalse, setScrollFalse] = useState(false);

  useEffect(() => {
    let params = `/user/resources?mediatype=PDF&resource_type=exam_prep&${
      classId ? 'class=' + classId + '&' : ''
    }${subject ? 'subject=' + subject : ''}`;
    params = params + `${subject || classId ? '&' : ''}page=1&count=8`;
    axios.get(api_url + params).then(
      (res) => {
        if (res) {
          setResources(res.data.data);
        }
      },
      (error) => {}
    );
    let countParams = `/user/resources/count?mediatype=PDF&resource_type=exam_prep&${
      classId ? 'class=' + classId + '&' : ''
    }${subject ? 'subject=' + subject : ''}`;
    countParams = countParams + `${subject || classId ? '&' : ''}`;
    axios.get(api_url + countParams).then(
      (res) => {
        if (res) {
          setTotalCount(res.data.data?.total);
        }
      },
      (error) => {}
    );
  }, [subject, classId]);

  const getData = (nPage) => {
    let params = `/user/resources?mediatype=PDF&resource_type=exam_prep&${
      classId ? 'class=' + classId + '&' : ''
    }${subject ? 'subject=' + subject : ''}`;
    params = params + `${subject || classId ? '&' : ''}page=${nPage}&count=8`;
    axios.get(api_url + params).then(
      (res) => {
        if (res && res.data.data.length > 0) {
          // const newR = resources;
          // _.map(res.data.data, (item) => {
          //   newR.push(item);
          // });
          setResources(res.data.data);
        } else {
          setScrollFalse(true);
        }
      },
      (error) => {}
    );
  };
  const handlePageChange = (e) => {
    setPage(e);
    getData(e);
  };

  // const onScroll = () => {
  //   if (timeout) {
  //     window.cancelAnimationFrame(timeout);
  //   }
  //   timeout = window.requestAnimationFrame(function () {
  //     const nPage = page + 1;
  //     setPage(nPage);
  //     !scrollFalse && getData(nPage);
  //   });
  // };

  // const debouncedCallback = debounce(onScroll, 500);

  // window.addEventListener("scroll", debouncedCallback, true);
  // window.removeEventListener("scroll", debouncedCallback);

  const Card = ({ item }) => (
    <div className='col mb-6'>
      <div className='card'>
        {item?.mediatype === 'VIMEO' ? (
          <div className='embed-container'>
            <Vimeo video={item.link.replace('https://vimeo.com/', '')} />
          </div>
        ) : (
          <img src={item?.thumbnail} className='card-img-top' />
        )}
        <div className='card-body'>
          <h5 className='card-title'>{item?.title}</h5>
          <p className='card-text'>{item?.desc}</p>
          {item?.mediatype !== 'VIMEO' && (
            <a href={item?.link} className='btn btn-outline-secondary'>
              Download Now
            </a>
          )}
        </div>
      </div>
    </div>
  );

  const HeaderForm = () => {
    return (
      <div className='row'>
        <div className='col md-'>
          <select
            className='custom-select custom-select-lg mb-3'
            style={{ width: '100%', padding: 8, borderRadius: 5 }}
            onChange={(e) => setClassId(e.target.value)}
            value={classId}
          >
            <option value=''>Select Class</option>
            <option value='10'>Class 10</option>
          </select>
        </div>
        <div className='col md-5'>
          <select
            className='custom-select custom-select-lg mb-3'
            onChange={(e) => setSubject(e.target.value)}
            style={{ width: '100%', padding: 8, borderRadius: 5 }}
            value={subject}
          >
            <option value=''>Select Subject</option>
            <option value='Science'>Science</option>
            <option value='Maths'>Maths</option>
            <option value='English'>English</option>
          </select>
        </div>
        <div className='col md-4' style={{ width: '100%' }}>
          {/* <button className="btn btn-secondary" style={{ width: "100%" }}>
            Create Resource
          </button> */}
        </div>
      </div>
    );
  };

  if (!localStorage.getItem('is_done')) {
    return <Redirect to={'/login'} />;
  }
  //console.log(localStorage.getItem('is_done'));

  return (
    <div>
      <Header />
      <div className='bg-grey'>
        <div className='container-fluid p-10'>
          <div className='row' style={{ padding: 20 }}>
            <div className='col-2 left-menu'>
              <br />
              <LeftMenu />
            </div>
            <div className='col-9 right-menu'>
              <HeaderForm />
              {resources ? (
                <div className='row'>
                  {_.map(resources, (item, index) => (
                    <>
                      <Card item={item} key={item?.title} />
                      {(index + 1) % 2 == 0 ? (
                        <div className='w-100' style={{ padding: 10 }}></div>
                      ) : (
                        index === resources.length - 1 && (
                          <>
                            <div className='col mb-6'></div>
                            <div
                              className='w-100'
                              style={{ padding: 10 }}
                            ></div>
                          </>
                        )
                      )}
                    </>
                  ))}
                </div>
              ) : (
                <h1></h1>
              )}
              {totalCount !== 0 ? (
                <div className='col-12 center flex justify-content-center'>
                  <Pagination
                    activePage={page}
                    itemsCountPerPage={8}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    itemClass='page-item'
                    linkClass='page-link'
                    innerClass='pagination justify-content-center'
                  />
                </div>
              ) : (
                <h1 style={{ textAlign: 'center', marginTop: 100 }}>
                  Coming Soon...
                </h1>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Resource;

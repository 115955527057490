import React from 'react';
import JsonData from '../cce.json';
import './CceTabel.css';
import {
  BrowserRouter as Router,
  Route,
  Link,
  BrowserRouter,
  useParams,
} from 'react-router-dom';

const CceTabel = ({ cceReport }) => {
  const { state } = useParams();
  const DisplayData = cceReport.map((info) => {
    return (
      <tr>
        {/* <td>{info.state}</td> */}
        <td>
          <Link to={`/districtperformance/${state}/${info.district}`}>
            {info.district}
          </Link>
        </td>
        <td>{info.schools}</td>
        <td>{info.students}</td>
        <td>
          <BrowserRouter>
            <Link
              to={'/performance-school/' + info.udise_id}
              target={info.udise_id}
            >
              {info.top_school_name}
            </Link>
          </BrowserRouter>
        </td>
      </tr>
    );
  });

  return (
    <div className='mar-topbot'>
      <div class='table-responsive'>
        <table stripped bordered hover responsive='sm'>
          <thead>
            <tr>
              {/* <th>State</th> */}
              <th>District</th>
              <th>School</th>
              <th>students</th>
              <th>SHOWCASE SCHOOL</th>
            </tr>
          </thead>
          <tbody>{DisplayData}</tbody>
        </table>
      </div>
    </div>
  );
};

export default CceTabel;

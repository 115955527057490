function CourseList({ courseList }) {
  const LeftCourse = ({ p }) => {
    return (
      <div className=' cardCusGap mb-3'>
        <div className='row g-0'>
          <div className='col-md-4'>
            <div className='sec'>
              <img
                src={require('./../../assets/te_3.jpg')}
                className=''
                alt='...'
              />
            </div>
          </div>
          <div className='col-md-8 '>
            <div className='card-body  cardBodyCus  cusMarLeft'>
              <h3 className='card-title skText'>{p.title}</h3>
              <h5>What is the course about? </h5>
              <p className='card-text'>
                The course covers the adnacement on technology in school
                education and technological tools available in Neeve app Lorem
                ipsum dolor sit amet.
              </p>
              <ul className='list-unstyled cusList'>
                <li>
                  <i className='fa-solid fa-list-ol'></i> Number of sessions - 4
                </li>
                <li>
                  <i className='fa-regular fa-clock'></i> Time - 3 hours 40 min
                </li>

                <li className='pt-3'>
                  {' '}
                  <a href='{{1}}' className='sbutton'>
                    Go to course
                  </a>{' '}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const RightCourse = ({ p }) => {
    return (
      <div className=' cardCusGap mb-3'>
        <div className='row g-0'>
          <div className='col-md-8 '>
            <div className='card-body  cardBodyCus  cusMarRight'>
              <h3 className='card-title skText'>{p.title}</h3>
              <h5>What is the course about?</h5>
              <p className='card-text'>
                The course covers the adnacement on technology in school
                education and technological tools available in Neeve app Lorem
                ipsum dolor sit amet.
              </p>
              <ul className='list-unstyled cusList'>
                <li>
                  <i className='fa-solid fa-list-ol'></i> Number of sessions - 4
                </li>
                <li>
                  <i className='fa-regular fa-clock'></i> Time - 3 hours 40 min
                </li>

                <li className='pt-3'>
                  {' '}
                  <a href='{{1}}' className='sbutton'>
                    Go to course
                  </a>{' '}
                </li>
              </ul>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='sec'>
              <img
                src={require('./../../assets/te_1.jpg')}
                className=''
                alt='...'
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {courseList.map((p, i) =>
        !(i % 2) ? (
          <div key={i}>
            <LeftCourse p={p} />
          </div>
        ) : (
          <div key={i}>
            <RightCourse p={p} />
          </div>
        )
      )}
    </>
  );
}

export default CourseList;

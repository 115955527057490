import axios from 'axios';
import { api_url } from '../resources/url';

const FORUM_TYPES = `/user/forum/types`;
const SUBJECT = `/user/filter/subjects`;
const FORUM_DATA = '/user/forum/posts';
const CLASSES = '/user/classes';
const COMMENTS_POST_DATA = '/user/forum/comment';
const DELETE_POST_DATA = '/user/forum/delete/post';
const DELETE_COMMENT_POST_DATA = '/user/forum/delete/comment';
const STATE_DASHBOARD = '/user/dashboard/state/';

const FORUM_POST_DATA = '/user/forum/post';

const DIST_DASHBOARD = '/user/dashboard/district/';
const DIST_LIST = '/user/districts/';

const TRAINING_DASHBOARD = {
  schoolDtl: '/user/training/',
  scormUrl: '/user/scorm/',
};

export const getQueriesData = () => {
  return axios.get(api_url + FORUM_TYPES);
};

export const getSubjectData = () => {
  return axios.get(api_url + SUBJECT);
};

export const getClassData = () => {
  return axios.get(api_url + CLASSES);
};

export const getForumData = (payload) => {
  return axios.post(api_url + FORUM_DATA, payload);
};

export const submitForumPostData = (payload) => {
  return axios.post(api_url + FORUM_POST_DATA, payload);
};

export const submitCommentPostData = (payload) => {
  return axios.post(api_url + COMMENTS_POST_DATA, payload);
};

export const deletePostData = (payload) => {
  return axios.post(api_url + DELETE_POST_DATA, payload);
};

export const deleteCommentPostData = (payload) => {
  return axios.post(api_url + DELETE_COMMENT_POST_DATA, payload);
};

export const getStateDashboardData = (state) => {
  return axios.get(api_url + STATE_DASHBOARD + state);
};

export const getDistDashboardData = (state, district) => {
  return axios.get(api_url + DIST_DASHBOARD, {
    params: {
      state: state,
      district: district,
    },
  });
};

export const getDistList = (state) => {
  return axios.get(api_url + DIST_LIST + state);
};

export const getSchoolDtlByUdiseId = (udiseId) => {
  return axios.get(
    api_url + TRAINING_DASHBOARD.schoolDtl + 'getUdiseDtl/' + udiseId
  );
};

export const getAllCourse = () => {
  return axios.get(api_url + TRAINING_DASHBOARD.scormUrl + 'getAllCourse');
};

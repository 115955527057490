import React from 'react';
import { Card } from 'react-bootstrap';
import { Tabs, Tab, Sonnet } from 'react-bootstrap';
import Heading from './Heading';
import OverallPerformance from './OverallPerformance';
import './PerformanceSchoolTab.css';
import PieChart from './PieChart';

import { jsPDF } from 'jspdf';
import { CSVLink } from 'react-csv';
function PerformanceSchoolTab({ slData, opData, state, dist }) {
  console.log('slData', opData);
  const pieDataEnglish = opData?.filter((item) => item.subject === 'English');
  const pieDataMaths = opData?.filter((item) => item.subject === 'Maths');
  const pieDataScience = opData?.filter((item) => item.subject === 'Science');

  const AspiringEn = pieDataEnglish.filter(
    (item) => item.level === 'Aspiring'
  )[0]?.total;
  const ScholarEn = pieDataEnglish.filter((item) => item.level === 'Scholar')[0]
    ?.total;
  const MasterEn = pieDataEnglish.filter((item) => item.level === 'Master')[0]
    ?.total;

  const AspiringMath = pieDataMaths.filter(
    (item) => item.level === 'Aspiring'
  )[0]?.total;
  const ScholarMath = pieDataMaths.filter((item) => item.level === 'Scholar')[0]
    ?.total;
  const MasterMath = pieDataMaths.filter((item) => item.level === 'Master')[0]
    ?.total;

  const AspiringSc = pieDataScience.filter(
    (item) => item.level === 'Aspiring'
  )[0]?.total;
  const ScholarSc = pieDataScience.filter((item) => item.level === 'Scholar')[0]
    ?.total;
  const MasterSc = pieDataScience.filter((item) => item.level === 'Master')[0]
    ?.total;
  const nTbl = [
    {
      subject: 'English',
      Aspiring: AspiringEn,
      Scholar: ScholarEn,
      Master: MasterEn,
    },
    {
      subject: 'Science',
      Aspiring: AspiringSc,
      Scholar: ScholarSc,
      Master: MasterSc,
    },
    {
      subject: 'Maths',
      Aspiring: AspiringMath,
      Scholar: ScholarMath,
      Master: MasterMath,
    },
  ];
  let headers = [
    { label: 'UDISE ID', key: 'udise_code' },
    { label: 'SCHOOL NAME', key: 'school_name' },
    { label: 'NEEVE USERS', key: 'no_users' },
    { label: 'ENROLLMENT', key: 'total_students' },
    { label: '%NEEVE USER', key: 'pct' },
    // { label: "SEE PERFORMANCE ON SCHOOL PAGE", key: "performance" },
  ];
  const pdfDownload = (e) => {
    {
      /*

            var content = document.getElementById("districtPerformance");
            var ifrmae = document.getElementById("ifmcontentstoprint");
            ifrmae.style.display = 'block'
            var pri = document.getElementById("ifmcontentstoprint").contentWindow;
            ifrmae.style.display = 'block'

            pri.document.open();
            pri.document.write(

              content.innerHTML);
            pri.document.close();
            pri.focus();
            pri.print();
            ifrmae.style.display = 'none'
*/
    }

    e.preventDefault();
    let doc = new jsPDF('landscape', 'pt', 'A4');
    const exportPdf = document.getElementById('exportPdf');
    exportPdf.remove();
    const nav = document.getElementsByTagName('nav');
    nav[0].remove();
    const selectBox = document.getElementById('select-box');
    selectBox.innerHTML = `<span>${dist}</span>`;
    let main = document.getElementById('districtPerformance');
    var div = document.createElement('div');
    div.setAttribute('id', 'newDivId');
    div.style.width = '1080px';
    div.appendChild(main);
    doc.html(div, {
      callback: () => {
        doc.save(`performance-school-${state}-${dist}.pdf`);
        window.location.reload(false);
      },
    });
  };
  return (
    <div>
      <Tabs
        defaultActiveKey='overall-performance'
        id='uncontrolled-tab-example'
        className='mb-3 nav-tabs-custom'
      >
        <Tab eventKey='overall-performance' title='Overall Performance'>
          <div
            id='exportPdf'
            style={{
              cursor: 'pointer',
              width: 144,
              display: 'inline-table',
              color: 'rgb(255, 255, 255)',
              backgroundColor: 'rgb(74, 74, 198)',
              borderColor: 'rgb(255, 255, 255)',
              borderRadius: '34px',
              border: '2px solid',
              padding: '6px 15px',
              textDecoration: 'none',
              fontWeight: '500',
            }}
          >
            <a onClick={pdfDownload}> Export to PDF</a>
          </div>
          <iframe
            id='ifmcontentstoprint'
            style={{
              height: '0px',
              width: '0px',
              position: 'absolute',
              display: 'none',
            }}
          ></iframe>

          <OverallPerformance opData={nTbl} type='op' />
          <div
            className='col-12'
            style={{
              backgroundColor: '#fff',
              paddingTop: 50,
              marginBottom: 50,
            }}
          >
            <div className='d-flex flex-column '>
              <div className='w-100 text-center '>
                {/*<Heading text="Percentage Breakdown" /> */}

                <div className='sub-head pt-4 pb-4'>
                  <p className=' text-center'>
                    This chart shows the performance of students across the
                    district in English, Maths and Science
                  </p>
                </div>
              </div>

              <div className='d-block neve-highlight '>
                <PieChart
                  pieDataEnglish={pieDataEnglish}
                  pieDataMaths={pieDataMaths}
                  pieDataScience={pieDataScience}
                />
              </div>
            </div>
          </div>
        </Tab>
        <Tab eventKey='school-list' title='School List'>
          <CSVLink
            data={slData}
            headers={headers}
            filename={`neeve-school-list-${state}-${dist}.csv`}
            style={{
              color: 'rgb(255, 255, 255)',
              backgroundColor: 'rgb(74, 74, 198)',
              borderColor: 'rgb(255, 255, 255)',
              borderRadius: '34px',
              border: '2px solid',
              padding: '6px 15px',
              textDecoration: 'none',
              fontWeight: '500',
            }}
          >
            Download School List Data
          </CSVLink>
          <OverallPerformance opData={slData} type='sl' />
        </Tab>
      </Tabs>
    </div>
  );
}

export default PerformanceSchoolTab;

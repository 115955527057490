import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from './../assets/logo.png';
import Header from './header-signup';
import Footer from './footer';
import bgimage from './../assets/bg-image.png';
import { Link } from 'react-router-dom';
import axios from 'axios';
import * as mod from './../resources/url';

function Login() {
  return (
    <div>
      <Header />
      <div className='singnupbg' style={{ backgroundImage: `url(${bgimage})` }}>
        <br />
        <br />
        <br />
        <br />

        <div className='container'>
          <div className='row text-center'>
            <div className='col-0 col-md-2'>
              <a></a>
            </div>

            <div className='col-0 col-md-8 formDiv'>
              <h2>Forgot Password?</h2>
              <h5>
                Enter the school email address associated with your account
              </h5>
              <p>
                We will email you an OTP on your school email ID to reset your
                password
              </p>

              <NameForm />
            </div>

            <div className='col-0 col-md-2'>
              <a></a>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}

class NameForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: '' };

    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    console.log(this.state.email);
    var email_id = this.state.email;
    var email = 0;

    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (
      this.state.email &&
      this.state.email != '' &&
      pattern.test(this.state.email)
    ) {
      console.log('UDISE Defined');
      document.getElementById('email-error-1').style.display = 'none';
      email = 1;
    } else {
      document.getElementById('email-error-1').style.display = 'block';
    }

    console.log(email);
    console.log(pattern.test(this.state.email));
    if (email === 1) {
      console.log('All OK');
      //const getemail = localStorage.getItem('forgot_email');
      //console.log(getemail);

      var apiBaseUrl = mod.api_url + '/user/web/otp';

      var data = {
        emailId: this.state.email,
      };
      console.log(data);

      axios
        .post(apiBaseUrl, data)
        .then(function (response) {
          console.log(response);

          if (response.status == 200) {
            //localStorage.setItem("u_code", encodeURIComponent(JSON.stringify(response.data.data)));
            localStorage.setItem('forgot_email', email_id);

            //localStorage.setItem('is_done', true);

            //alert(response.data.data.accessToken);

            window.location.href = `/forgotpassword_verifyotp`;

            //console.log("Login successfull");
          } else {
            alert(response.data.message);
          }
        })
        .catch(function (error) {
          console.log(error);
          document.getElementById('email-error-1').style.display = 'block';
        });
    }
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <input
          className='input-text-box'
          type='text'
          name='schoolemail'
          onChange={this.handleChangeEmail}
          placeholder='School Email'
        />
        <h6 id='email-error-1' className='error-msg'>
          <span>🠕</span> Please enter a valid email id
        </h6>

        <input
          className='btn header-nav-btn-primary'
          type='submit'
          value='Send'
        />
      </form>
    );
  }
}
export default Login;

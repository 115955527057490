import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import Login from './components/login';
import Signup from './components/signup';
import Home from './components/home';
import licence_key_verificaton from './components/licence_key_verificaton';
import Dashboard from './components/dashboard';
import ForgotPassword from './components/forgotpassword';
import ForgotPasswordVerifyOTP from './components/forgotpassword_verifyotp';
import ForgotPasswordNewPassword from './components/forgotpassword_newpassword';
import ForgotPasswordVerifySuccess from './components/forgotpassword_verifysuccess';
import Resource from './components/resource';
import ResourceStudent from './components/resource-student';
import VideoComponent from './components/video';
import Forum from './components/forum';
import WorksheetMaker from './components/worksheet-maker';
import SchoolConnect from './components/school-connect';
import PerformanceStudent from './components/performance-students';
import PerformanceStudentIndividual from './components/performance-student-individual';
import PerformanceSchool from './components/performance-school';
import PerformanceClass from './components/performance-class';
import ResourceQlib from './components/resource-qlib';
import ResourceLessonPlan from './components/resource-lessonplan';
import ResourceExamPrep from './components/resource-exam-prep';
import StatedashboardStatic from './components/StatedashboardStatic';
import DistrictPerformance from './components/DistrictPerformance';
import CceTabel from './components/CceTabel';
import Card from 'react-bootstrap/Card';
import PerformingCardSlider from './components/PerformingCardSlider';
import NeeveTraining from './components/training/neeve-training';

/*
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}
*/
function App() {
  return (
    <div>
      <main>
        <Switch>
          <Route path='/login' component={Login} />

          <Route path='/signup' component={Signup} />
          <Route
            path='/licence_key_verificaton'
            component={licence_key_verificaton}
          />
          <Route
            path='/statedashboard/:state'
            component={StatedashboardStatic}
          />
          <Route
            path='/districtperformance/:state/:dist'
            component={DistrictPerformance}
          />
          <Route path='/dashboard' component={Dashboard} />
          <Route path='/resource/teachers' component={Resource} />
          <Route path='/resource/qlib' component={ResourceQlib} />
          <Route path='/resource/exam-prep' component={ResourceExamPrep} />
          <Route path='/resource/lesson-plans' component={ResourceLessonPlan} />
          <Route
            path='/resource/student-readiness'
            component={ResourceStudent}
          />
          <Route path='/video' component={VideoComponent} />
          <Route path='/forum' component={Forum} />
          <Route path='/forgotpassword' component={ForgotPassword} />
          <Route
            path='/forgotpassword_verifyotp'
            component={ForgotPasswordVerifyOTP}
          />
          <Route
            path='/forgotpassword_newpassword'
            component={ForgotPasswordNewPassword}
          />
          <Route
            path='/forgotpassword_verifysuccess'
            component={ForgotPasswordVerifySuccess}
          />
          <Route path='/worksheet-maker' component={WorksheetMaker} />
          <Route path='/school-connect' component={SchoolConnect} />
          <Route path='/performance-students' component={PerformanceStudent} />
          <Route
            path='/performance-student-individual'
            component={PerformanceStudentIndividual}
          />
          <Route
            path='/performance-school/:udescId'
            component={PerformanceSchool}
          />
          <Route path='/performance-school/' component={PerformanceSchool} />
          <Route path='/performance-class' component={PerformanceClass} />
          {/* <Route path="/" component={Home} /> */}

          <Route path='/signup' component={Signup} />
          <Route
            path='/licence_key_verificaton'
            component={licence_key_verificaton}
          />
          <Route path='/dashboard' component={Dashboard} />
          <Route path='/video' component={VideoComponent} />
          <Route path='/forum' component={Forum} />
          <Route path='/forgotpassword' component={ForgotPassword} />
          <Route
            path='/forgotpassword_verifyotp'
            component={ForgotPasswordVerifyOTP}
          />
          <Route
            path='/forgotpassword_newpassword'
            component={ForgotPasswordNewPassword}
          />
          <Route
            path='/forgotpassword_verifysuccess'
            component={ForgotPasswordVerifySuccess}
          />
          <Route path='/training/dashboard' component={NeeveTraining} />
          <Route path='/' component={Home} />
        </Switch>
      </main>

      <Footer />
    </div>
  );
}
export default App;

import React from 'react';
import './../custom.css';

import { NavLink } from 'react-router-dom';
import logo from './../assets/logo.png';
import bgimage from './../assets/bg-image.png';
import key_logo from './../assets/key_logo.svg';
import Header from './header-signup';
import Footer from './footer';
import { Link } from 'react-router-dom';

import axios from 'axios';
import * as mod from './../resources/url';

const fetchData = () => {
  return fetch(mod.api_url + '/user/udises')
    .then((response) => response.json())
    .then((data) => console.log(data.data));
};

function Signup() {
  return (
    <div>
      <Header />
      <div className='singnupbg' style={{ backgroundImage: `url(${bgimage})` }}>
        <br />
        <br />
        <div className='container'>
          <div className='row text-center'>
            <div className='col-0 col-md-2'>
              <a></a>
            </div>

            <div className='col-0 col-md-8 formDiv'>
              <img src={key_logo} style={{ width: '25%' }} />
              <h2>Enter Your License Key</h2>
              <br />
              <NameForm />
            </div>

            <div className='col-0 col-md-2'>
              <a></a>
            </div>
          </div>
        </div>
        <br />
      </div>
    </div>
  );
}

class NameForm extends React.Component {
  constructor(props) {
    super(props);
    //const search = props.location.search; // returns the URL query String

    this.state = { value: '' };
    this.state = { udiseid: '' };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.keyDesc = this.keyDesc.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });

    fetchData();
  }

  handleChangeEmail(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    //alert('Last submitted Value: ' + this.state.value);
    console.log('Submit Clicked');
    console.log(this.state.udiseid);
    console.log(this.state.value);
    if (this.state.value) {
      var apiBaseUrl = mod.api_url + '/user/web/license/verify';

      var data = {
        udiseId: this.state.udiseid,
        licenseKey: this.state.value,
      };
      console.log(data);

      axios
        .post(apiBaseUrl, data)
        .then(function (response) {
          console.log(response);

          if (response.status == 200) {
            //alert("Your License Key is accepted, please login.");
            const temp_pass = localStorage.getItem('temp_pass');

            localStorage.setItem(
              'u_code',
              encodeURIComponent(JSON.stringify(response.data.data))
            );

            localStorage.setItem('is_done', true);

            var apiBaseUrl2 = mod.api_url + '/user/web/login';

            var data2 = {
              udiseId: data.udiseId,
              password: temp_pass,
            };
            console.log(data2);
            localStorage.setItem('udise_id', data.udiseId);

            axios
              .post(apiBaseUrl2, data2)
              .then(function (response2) {
                console.log(response2);

                if (response2.status == 200) {
                  //alert("You are Logged in.");

                  //localStorage.setItem("u_code", encodeURIComponent(JSON.stringify(response.data.data)));

                  console.log(response2);
                  localStorage.setItem('is_done', true);

                  localStorage.setItem('udise_id', response2.data.data.id);
                  localStorage.setItem(
                    'school_name',
                    response2.data.data.school_name
                  );
                  localStorage.setItem('state', response2.data.data.state);

                  window.location.href = `/resource/teachers`;

                  console.log('Login1 successfull');
                } else {
                  alert(response2.data.message);
                }
              })
              .catch(function (error) {
                alert('Login Issue Please retry to login');
                window.location.href = `/login`;

                console.log(error);
              });

            //window.location.href = `/login`;

            console.log('Login1 successfull');
          } else {
            alert(response.data.message);
          }
        })
        .catch(function (error) {
          alert(
            'You have entered an invalid license key. Please enter the correct license key. Email support@neeve.io with your school details if you do not have a license key'
          );
          console.log(error);
        });
    }
  }
  keyDesc() {
    alert(
      'A license key is an access code that your school has shared with you. If you do not have a license key, please contact your school administrator.'
    );
  }
  render() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.state.udiseid = urlParams.get('udise');
    console.log(this.state.udiseid);
    //const params = new URLSearchParams(search);
    //IdFromURL = params.get('udise');

    return (
      <form onSubmit={this.handleSubmit}>
        <input
          className='input-text-box'
          type='text'
          name='licencekey'
          onChange={this.handleChange}
          placeholder='Enter Your License Key'
        />
        <div className='more-info row text-right'>
          <div className='col'>
            <a style={{ cursor: 'pointer' }} onClick={this.keyDesc}>
              What is a license key?
            </a>
          </div>
        </div>

        <input
          className='btn header-nav-btn-primary'
          type='submit'
          value='Submit'
        />
      </form>
    );
  }
}
export default Signup;

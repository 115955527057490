import Select from 'react-select';
import * as mod from './../resources/url';
import React from 'react';
import {
  getQueriesData,
  getSubjectData,
  getForumData,
  getClassData,
} from '../services/api';
import * as _ from 'lodash';
import { useEffect, useState } from 'react';
import axios from 'axios';

function createform() {
  return (
    <div>
      <NameForm />
    </div>
  );
}

class NameForm extends React.Component {
  state = {
    types: [],
    subjects: [],
    class: [],
    typeselect: '612dcb0014921b9fecae6772',
    subjectselect: '',
    classselect: '',
    comment: '',
    visibilityselect: 'all',
  };

  constructor(props) {
    super(props);

    this.handleTypes = this.handleTypes.bind(this);
    this.handleSubjects = this.handleSubjects.bind(this);
    this.handleClass = this.handleClass.bind(this);
    this.handleComment = this.handleComment.bind(this);
    this.handleVisibility = this.handleVisibility.bind(this);

    //this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
  }

  handleTypes(event) {
    //var val = Number(event.value) - 1 ;
    //console.log(val);
    console.log(event);
    this.setState({ typeselect: event.value });
    console.log(this.state.typeselect);
    //fetchData();
    //this.setState({financialGoal: event.target.value.replace(/\D/,'')}
  }

  handleSubjects(event) {
    //var val = Number(event.value) - 1 ;
    //console.log(val);
    console.log(event);
    this.setState({ subjectselect: event.value });
    //this.setState({typeselect: event.target.value});
    console.log(this.state.subjectselect);
    //fetchData();
    //this.setState({financialGoal: event.target.value.replace(/\D/,'')}
  }

  handleClass(event) {
    //var val = Number(event.value) - 1 ;
    //console.log(val);
    console.log(event);
    this.setState({ classselect: event.value });
    //this.setState({typeselect: event.target.value});
    console.log(this.state.classselect);
    //fetchData();
    //this.setState({financialGoal: event.target.value.replace(/\D/,'')}
  }

  handleComment(event) {
    console.log(event.target.value);
    this.setState({ comment: event.target.value });
  }

  handleVisibility(event) {
    //console.log(event.target.value);
    this.setState({ visibilityselect: event.value });
  }

  handleSubmitForm() {
    console.log('submit Clicked');

    var typeselect = this.state.typeselect;
    var visibilityselect = this.state.visibilityselect;
    var subjectselect = this.state.subjectselect;
    var classselect = this.state.classselect;
    var comment = this.state.comment;
    //alert(comment);
    console.log(typeselect);

    var apiBaseUrl = mod.api_url + '/user/forum/post';
    /*
        var data={
            userId: '{ "_id": "'+localStorage.getItem('udise_id')+'"}',
            userName:'',
            typeId: '{ "_id": "'+this.state.typeselect+'"}',
            text: this.state.comment,
            classId:'{ "_id": "'+this.state.classselect+'"}',
            subjectId:'{ "_id": "'+this.state.subjectselect+'"}'
        }
*/
    var data = {
      userId: { _id: localStorage.getItem('udise_id') },
      typeId: { _id: typeselect },
      udiseId: { _id: localStorage.getItem('udise_id') },
      classId: { _id: classselect },
      subjectId: { _id: subjectselect },
      userName: { _id: localStorage.getItem('school_name') },
      text: comment,
      visibility: visibilityselect,
    };
    var headers = {
      'Access-Control-Allow-Origin': 'POST',
    };

    console.log(data);

    axios
      .post(apiBaseUrl, data)
      .then(function (response) {
        console.log(response);

        if (response.status == 200) {
          alert('Your Post is Submitted Successfully');
          window.location.href = '/forum';
          //window.location.href = `/licence_key_verificaton?udise=${data.udiseId}`;

          //console.log("Login1 successfull");
        } else {
          alert(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
        alert('Unable to Post comment');
      });

    //console.log(typeselect, subjectselect, classselect, comment);
  }

  componentDidMount() {
    Promise.all([
      fetch(mod.api_url + '/user/forum/types'),
      fetch(mod.api_url + '/user/filter/subjects'),
      fetch(mod.api_url + '/user/classes'),
    ])

      .then(([res1, res2, res3]) => {
        return Promise.all([res1.json(), res2.json(), res3.json()]);
      })
      .then(([res1, res2, res3]) => {
        // set state in here
        this.setState({ types: res1, subjects: res2, class: res3 });
      });
  }

  render() {
    //console.log(this.state.category);
    console.log(this.state);
    console.log('Changes');

    //alert(this.state.types);

    var typesrow = [];
    var subjectsrow = [];
    var classrow = [];

    /*
      if(this.state.types.data){
        console.log(this.state.types.data.length);
        for (var i = 0; i < this.state.types.data.length; i++) {
            typesrow.push({value: this.state.types.data[i]._id, label: this.state.types.data[i].type});
            
        }
      }
      */
    typesrow.push({ value: '612dcb0014921b9fecae6772', label: 'Teachers' });
    typesrow.push({ value: '612dcb3014921b9fecae6773', label: ' Students' });
    typesrow.push({
      value: '612dcb3014921b9fecae6774',
      label: 'Teachers + Students',
    });

    var default_subject = '';
    if (this.state.subjects.data) {
      console.log(this.state.subjects.data.length);
      for (var i = 0; i < this.state.subjects.data.length; i++) {
        subjectsrow.push({
          value: this.state.subjects.data[i]._id,
          label: this.state.subjects.data[i].subject,
        });
      }
    }

    if (this.state.class.data) {
      console.log(this.state.class.data.length);
      for (var i = 0; i < this.state.class.data.length; i++) {
        classrow.push({
          value: this.state.class.data[i]._id,
          label: this.state.class.data[i].class,
        });
      }
    }
    var visibilityrow = [
      { value: 'all', label: 'School + Students' },
      { value: 'school', label: 'School Only' },
    ];

    /*
      if(!this.state.types){
        return null;
    }else{
        for (var i = 0; i < this.state.types.data.length; i++) {
            typesrow.push({value: i+1, label: this.state.types.data[i].type});
            
        }
    }
    
    */

    return (
      <form>
        <textarea
          id='school_name'
          className='input-textboxx'
          name='schoolname'
          placeholder='Type Your Message'
          onChange={this.handleComment}
        ></textarea>

        <p className='label-span'>TO</p>
        <Select
          id='select_category'
          name='select_category'
          className='input-text-boxx'
          placeholder='School Notice'
          options={typesrow}
          onChange={this.handleTypes}
        />

        <p className='label-spanx1'>CLASS</p>
        <Select
          id='select_category'
          name='select_category'
          className='input-text-boxx1'
          placeholder='All Classes'
          options={classrow}
          onChange={this.handleClass}
        />

        <p className='label-spanx2'>SUBJECT</p>
        <Select
          id='select_category'
          name='select_category'
          className='input-text-boxx2'
          placeholder='All Subjects'
          options={subjectsrow}
          onChange={this.handleSubjects}
        />

        <p className='label-spanx3'>ATTACH</p>
        <input type='file' className='input-text-box3'></input>

        {/* <Select 
                id="select_category"
                name="select_category"
                className="input-text-box"
                placeholder="School + Students"
                options={visibilityrow}
                onChange={this.handleVisibility}

            /> */}

        <input
          className='btnx header-nav-btn-primary'
          value='SEND'
          onClick={this.handleSubmitForm}
        />
      </form>
    );
  }
}

export default createform;

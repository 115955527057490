import Header from './header-signedin';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { api_url } from '../resources/url';
import * as _ from 'lodash';
import { Link } from 'react-router-dom';
import debounce from 'lodash.debounce';

import Vimeo from '@u-wave/react-vimeo';
import LeftMenu from './left-menu';
import { Redirect } from 'react-router';
import Pagination from 'react-js-pagination';
import { useMemo } from 'react';
var timeout = 1000;

function VideoComponent() {
  const [classId, setClassId] = useState('6');
  const [subject, setSubject] = useState('Science');
  const [category, setCategory] = useState('');
  const [catList, setCatList] = useState();

  const [resources, setResources] = useState();
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [scrollFalse, setScrollFalse] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    let params = `/user/resources?mediatype=VIMEO&${
      classId ? 'class=' + classId + '&' : ''
    }${subject ? 'subject=' + subject + '&' : ''}${
      category ? 'category=' + category : ''
    }`;
    params =
      params + `${subject || classId || category ? '&' : ''}page=1&count=8`;
    axios
      .get(api_url + params)
      .then(
        (res) => {
          if (res) {
            setResources(res.data.data);
          }
        },
        (error) => {}
      )
      .finally(() => {
        setLoading(false);
      });
    let countParams = `/user/resources/count?mediatype=VIMEO&${
      classId ? 'class=' + classId + '&' : ''
    }${subject ? 'subject=' + subject + '&' : ''}${
      category ? 'category=' + category : ''
    }`;
    countParams = countParams + `${subject || classId || category ? '&' : ''}`;
    axios
      .get(api_url + countParams)
      .then(
        (res) => {
          if (res) {
            setTotalCount(res.data.data?.total);
          }
        },
        (error) => {}
      )
      .finally(() => {
        setLoading(false);
      });
  }, [subject, classId, category]);

  useMemo(() => {
    setCategory('');
    axios
      .get(
        api_url + `/user/resources/category?class=${classId}&subject=${subject}`
      )
      .then(
        (res) => {
          if (res) {
            setCatList(res.data.data);
          }
        },
        (error) => {}
      )
      .finally(() => {
        setLoading(false);
      });
  }, [subject, classId]);

  const getData = (nPage) => {
    setLoading(true);
    let params = `/user/resources?mediatype=VIMEO&${
      classId ? 'class=' + classId + '&' : ''
    }${subject ? 'subject=' + subject + '&' : ''}${
      category ? 'category=' + category : ''
    }`;
    params =
      params +
      `${subject || classId || category ? '&' : ''}page=${nPage}&count=8`;
    axios
      .get(api_url + params)
      .then(
        (res) => {
          if (res && res.data.data.length > 0) {
            setResources(res.data.data);
          } else {
            setScrollFalse(true);
          }
        },
        (error) => {}
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePageChange = (e) => {
    setPage(e);
    getData(e);
  };

  // const onScroll = () => {
  //   if (timeout) {
  //     window.cancelAnimationFrame(timeout);
  //   }
  //   timeout = window.requestAnimationFrame(function () {
  //     const nPage = page + 1;
  //     setPage(nPage);
  //     !scrollFalse && getData(nPage);
  //   });
  // };

  // const debouncedCallback = debounce(onScroll, 500);

  // window.addEventListener("scroll", debouncedCallback, true);
  // window.removeEventListener("scroll", debouncedCallback);

  const Card = ({ item }) => (
    <div className='col-6'>
      <div className='card'>
        {item?.mediatype === 'VIMEO' ? (
          <div className='embed-container video-container'>
            <Vimeo video={item.link.replace('https://vimeo.com/', '')} />
          </div>
        ) : (
          <img src={item?.thumbnail} className='card-img-top' />
        )}
        <div className='card-body'>
          <h5 className='card-title'>{item?.title}</h5>
          <p className='card-text'>{item?.desc}</p>
          {item?.mediatype !== 'VIMEO' && (
            <a href={item?.link} className='btn btn-outline-secondary'>
              Download Now
            </a>
          )}
        </div>
      </div>
    </div>
  );

  const HeaderForm = () => {
    return (
      <div className='row'>
        <div className='col md-4'>
          <select
            className='custom-select custom-select-lg mb-3'
            style={{ width: '100%', padding: 8, borderRadius: 5 }}
            onChange={(e) => setClassId(e.target.value)}
            value={classId}
          >
            <option value=''>Select Class</option>
            <option value='6'>Class 6</option>
            <option value='7'>Class 7</option>
            <option value='8'>Class 8</option>
            <option value='9'>Class 9</option>
            <option value='10'>Class 10</option>
          </select>
        </div>
        <div className='col md-4'>
          <select
            className='custom-select custom-select-lg mb-3'
            onChange={(e) => setSubject(e.target.value)}
            style={{ width: '100%', padding: 8, borderRadius: 5 }}
            value={subject}
          >
            <option value=''>Select Subject</option>
            <option value='Science'>Science</option>
            <option value='Maths'>Maths</option>
          </select>
        </div>
        <div className='col md-4' style={{ width: '100%' }}>
          <select
            className='custom-select custom-select-lg mb-3'
            onChange={(e) => setCategory(e.target.value)}
            style={{ width: '100%', padding: 8, borderRadius: 5 }}
            value={category}
          >
            <option value=''>Select Category</option>
            {catList?.map((item) => (
              <option value={item.category}>{item.category}</option>
            ))}
          </select>
        </div>
      </div>
    );
  };

  if (!localStorage.getItem('is_done')) {
    return <Redirect to={'/login'} />;
  }

  return (
    <div>
      <Header />
      <div className='bg-grey min-height'>
        <div className='container-fluid p-10'>
          <div className='row' style={{ padding: 20 }}>
            <div className='col-2 left-menu'>
              <br />
              <LeftMenu />
            </div>
            {!loading ? (
              <div className='col-9 right-menu'>
                <HeaderForm />
                {resources ? (
                  <div className='row'>
                    {_.map(resources, (item, index) => (
                      <>
                        <Card item={item} key={item?.title} />
                        {(index + 1) % 2 == 0 ? (
                          <div className='w-100' style={{ padding: 10 }}></div>
                        ) : (
                          index === resources.length - 1 && (
                            <>
                              <div className='col mb-6'></div>
                              <div
                                className='w-100'
                                style={{ padding: 10 }}
                              ></div>
                            </>
                          )
                        )}
                      </>
                    ))}
                  </div>
                ) : (
                  <h1></h1>
                )}
                <div className='col-12 center flex justify-content-center'>
                  <Pagination
                    activePage={page}
                    itemsCountPerPage={8}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    itemClass='page-item'
                    linkClass='page-link'
                    innerClass='pagination justify-content-center'
                  />
                </div>
              </div>
            ) : (
              <div
                className='col-9  justify-content-center align-content-center align-items-center'
                style={{ display: 'flex' }}
              >
                <div
                  className='spinner-border text-success'
                  role='status'
                ></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoComponent;

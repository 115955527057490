import { Link, useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import dashboard_icon from './../assets/dashboard-icon.png';
import performance_logo from './../assets/noun_Graph_1928752.svg';
import resource_logo from './../assets/noun_resources_3870957.svg';
import forum_logo from './../assets/noun_forums_1870692.svg';
import video_logo from './../assets/1159798.svg';
import worksheet_logo from './../assets/1548674.svg';
import schoolIcon from './../assets/schoolIcon.png';

function LeftMenu() {
  const location = useLocation();
  console.log('Left Menu Starts: ' + location.pathname);

  var path_arr = location.pathname.split('/');
  var path_length = path_arr.length;
  var path_name = '';

  //var { state, district} = useParams();
  var district = localStorage.getItem('district');
  var state = localStorage.getItem('state');
  console.log(state);
  console.log(district);

  const openWorkSheet = () => {
    const u_code = localStorage
      .getItem('u_code')
      .replace('"', '')
      .replace('"', '')
      .replace('%22', '')
      .replace('%22', '');
    const user_id = localStorage
      .getItem('user_id')
      .replace('"', '')
      .replace('"', '');
    //console.log(u_code, user_id)
    const url = `${process.env.REACT_APP_WW_URL}/?udise_code=${user_id}&auth_token=${u_code}`;
    //const url = `http://worksheetwarehouse.wiseowllearning.io/?udise_code=${user_id}&auth_token=${u_code}`;
    window.open(url);
  };

  if (path_arr) {
    path_name = path_arr[path_length - 1];
  }

  // var statedashboard = (
  //   <Link className="btn left-menu-btn" to="/Statedashboard/Sikkim">
  //     <h5>State Performance</h5>
  //   </Link>
  // );

  var statedashboard = (
    <Link className='btn left-menu-btn' to={'/Statedashboard/' + state}>
      <h5>State Performance</h5>
    </Link>
  );

  var districtperformance = (
    <Link
      className='btn left-menu-btn'
      to={'/districtperformance/' + state + '/' + district}
    >
      <h5>District Performance</h5>
    </Link>
  );

  var performance = (
    <Link className='btn left-menu-btn' to='/dashboard'>
      <h5>Performance</h5>
    </Link>
  );
  var teacher_resc = (
    <Link
      className='btn left-menu-subheading btn-lesspad'
      to='/resource/teachers'
    >
      <h5>21st Century Skills </h5>
    </Link>
  );
  var student_resc = (
    <Link
      className='btn left-menu-subheading btn-lesspad'
      to='/resource/student-readiness'
    >
      <h5>Readiness Sprint</h5>
    </Link>
  );
  var qlib_resc = (
    <Link className='btn left-menu-subheading btn-lesspad' to='/resource/qlib'>
      <h5>QLib</h5>
    </Link>
  );
  var lesson_plans_resc = (
    <Link
      className='btn left-menu-subheading btn-lesspad'
      to='/resource/lesson-plans'
    >
      <h5>Lesson Plans</h5>
    </Link>
  );
  var exam_prep_resc = (
    <Link className='btn left-menu-subheading' to='/resource/exam-prep'>
      <h5>Board Exam Prep</h5>
    </Link>
  );
  var videos_lnk = (
    <Link className='btn left-menu-btn' to='/video'>
      <h5>Videos</h5>
    </Link>
  );
  var worksheet_mkr = (
    <Link className='house btn left-menu-btn' onClick={openWorkSheet}>
      <h5>
        <b>
          <u>Worksheet Warehouse</u>
        </b>
      </h5>
    </Link>
  );
  var school_cnt = (
    <Link className='btn left-menu-btn' to='/forum'>
      <h5>School Diary</h5>
    </Link>
  );

  var perfor_students = (
    <Link
      className='btn left-menu-subheading btn-lesspad'
      to='/performance-students'
    >
      <h5>Students</h5>
    </Link>
  );
  var perfor_school = (
    <Link
      className='btn left-menu-subheading btn-lesspad'
      to='/performance-school'
    >
      <h5>School</h5>
    </Link>
  );
  var perfor_class = (
    <Link
      className='btn left-menu-subheading btn-lesspad'
      to='/performance-class'
    >
      <h5>Class</h5>
    </Link>
  );

  if (path_name == 'statedashboard') {
    statedashboard = (
      <Link
        className='btn left-menu-btn path-selected'
        //        to="/statedashboard/Sikkim"
        to={'/statedashboard/' + state}
      >
        <h5>State Dashboard</h5>
      </Link>
    );
  } else if (path_name == 'districtperformance') {
    districtperformance = (
      <Link
        className='btn left-menu-btn path-selected'
        to={'/districtperformance/' + state + '/' + district}
      >
        <h5>District Performance</h5>
      </Link>
    );
  } else if (path_name == 'dashboard') {
    performance = (
      <Link className='btn left-menu-btn path-selected' to='/dashboard'>
        <h5>Performance</h5>
      </Link>
    );
  } else if (path_name == 'teachers') {
    teacher_resc = (
      <Link
        className='btn left-menu-subheading  btn-lesspad path-selected'
        to='/resource/teachers'
      >
        <h5>21st Century Skills</h5>
      </Link>
    );
  } else if (path_name == 'student-readiness') {
    student_resc = (
      <Link
        className='btn left-menu-subheading btn-lesspad path-selected'
        to='/resource/student-readiness'
      >
        <h5>Readiness Sprint</h5>
      </Link>
    );
  } else if (path_name == 'video') {
    videos_lnk = (
      <Link className='btn left-menu-btn path-selected' to='/video'>
        <h5>Videos</h5>
      </Link>
    );
  } else if (path_name == 'worksheet-maker') {
    worksheet_mkr = (
      <Link className='btn left-menu-btn path-selected' onClick={openWorkSheet}>
        <h5>Worksheet Warehouse</h5>
      </Link>
    );
  } else if (path_name == 'forum') {
    school_cnt = (
      <Link className='btn left-menu-btn path-selected' to='/forum'>
        <h5>School Diary</h5>
      </Link>
    );
  } else if (
    path_name == 'performance-students' ||
    path_name == 'performance-student-individual'
  ) {
    perfor_students = (
      <Link
        className='btn left-menu-subheading path-selected'
        to='/performance-students'
      >
        <h5>Students</h5>
      </Link>
    );
  } else if (path_name == 'performance-school') {
    perfor_school = (
      <Link
        className='btn left-menu-subheading path-selected'
        to='/performance-school'
      >
        <h5>School</h5>
      </Link>
    );
  } else if (path_name == 'performance-class') {
    perfor_class = (
      <Link
        className='btn left-menu-subheading btn-lesspad path-selected'
        to='/performance-class'
      >
        <h5>Class</h5>
      </Link>
    );
  } else if (path_name == 'qlib') {
    qlib_resc = (
      <Link
        className='btn left-menu-subheading path-selected'
        to='/resource/qlib'
      >
        <h5>QLib</h5>
      </Link>
    );
  } else if (path_name == 'lesson-plans') {
    lesson_plans_resc = (
      <Link
        className='btn left-menu-subheading btn-lesspad path-selected'
        to='/resource/lesson-plans'
      >
        <h5>Lesson Plans</h5>
      </Link>
    );
  } else if (path_name == 'exam-prep') {
    exam_prep_resc = (
      <Link
        className='btn left-menu-subheading btn-lesspad'
        to='/resource/exam-prep'
      >
        <h5>Board Exam Prep</h5>
      </Link>
    );
  }

  console.log(location.pathname.split('/'));
  console.log(path_arr[path_length - 1]);
  console.log('path_name', path_name);
  const role = localStorage.getItem('role');
  console.log(role);
  return (
    <div>
      {role === 'Admin' ? (
        <>
          <div className='row'>
            <div className='col-2'>
              <img className='left-menu-img' src={dashboard_icon} />
            </div>
            <div className='col-10'>{statedashboard}</div>
          </div>
          <div className='row'>
            <div className='col-2'>
              <img className='left-menu-img' src={schoolIcon} />
            </div>
            <div className='col-10'>{districtperformance}</div>
          </div>
        </>
      ) : (
        <>
          <div className='row'>
            <div className='col-2'>
              <img className='left-menu-img' src={performance_logo} />
            </div>
            <div className='col-10'>
              {performance}
              <hr className='left-menu-hr' />
              <div className='row '>
                <div className='col-1'></div>
                <div className='col-10' style={{ paddingRight: '4px' }}>
                  {perfor_students}
                  <hr className='left-menu-hr' />
                  {perfor_class}
                  <hr className='left-menu-hr' />
                  {perfor_school}
                  <hr className='left-menu-hr' />
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-2'>
              <img className='left-menu-img' src={resource_logo} />
            </div>
            <div className='col-10'>
              <Link className='btn left-menu-btn'>
                <h5>Resources</h5>
              </Link>
              <hr className='left-menu-hr' />

              <div className='row '>
                <div className='col-1'></div>
                <div className='col-10' style={{ paddingRight: '4px' }}>
                  {teacher_resc}
                  <hr className='left-menu-hr' />
                </div>
              </div>

              <div className='row '>
                <div className='col-1'></div>
                <div className='col-10' style={{ paddingRight: '4px' }}>
                  {student_resc}
                  <hr className='left-menu-hr' />
                </div>
              </div>

              <div className='row '>
                <div className='col-1'></div>
                <div className='col-10' style={{ paddingRight: '4px' }}>
                  {qlib_resc}
                  <hr className='left-menu-hr' />
                </div>
              </div>

              <div className='row '>
                <div className='col-1'></div>
                <div className='col-10' style={{ paddingRight: '4px' }}>
                  {lesson_plans_resc}
                  <hr className='left-menu-hr' />
                </div>
              </div>
              <div className='row '>
                <div className='col-1'></div>
                <div className='col-10' style={{ paddingRight: '4px' }}>
                  {exam_prep_resc}
                  <hr className='left-menu-hr' />
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-2'>
              <img className='left-menu-img' src={video_logo} />
            </div>
            <div className='col-10'>
              {videos_lnk}
              <hr className='left-menu-hr' />
            </div>
          </div>
          <div className='row'>
            <div className='col-2'>
              <img className='left-menu-img' src={worksheet_logo} />
            </div>
            <div className='col-10'>
              {worksheet_mkr}
              <hr className='left-menu-hr' />
            </div>
          </div>
          <div className='row'>
            <div className='col-2'>
              <img className='left-menu-img' src={forum_logo} />
            </div>
            <div className='col-10'>
              {school_cnt}
              <hr className='left-menu-hr' />
            </div>
          </div>
        </>
      )}
      {/*
                <div className="row">
                  <div className="col-2">
                    <img className="left-menu-img" src={forum_logo} />
                  </div>
                  <div className="col-10">
                    
                    <Link className="btn left-menu-btn" to="/forum"><h5>Forum</h5></Link>
                  </div>
                </div>
                  */}
    </div>
  );
}

export default LeftMenu;

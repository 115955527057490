import React from 'react';
import img2 from '../assets/img2.jpg';
import { Card } from 'react-bootstrap';

function CardUi() {
  const cardInfo = [
    {
      image:
        'https://i.picsum.photos/id/0/5616/3744.jpg?hmac=3GAAioiQziMGEtLbfrdbcoenXoWAW-zlyEAMkfEdBzQ',
      title: 'title 1',
      subheading: 'Rank 1',
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy",
    },
    {
      image:
        'https://i.picsum.photos/id/1002/4312/2868.jpg?hmac=5LlLE-NY9oMnmIQp7ms6IfdvSUQOzP_O3DPMWmyNxwo',
      title: 'title 2',
      subheading: 'Rank 2',
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy",
    },
    {
      image:
        'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
      title: 'title 3',
      subheading: 'Rank 3',
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy",
    },
    {
      image:
        'https://i.picsum.photos/id/1029/4887/2759.jpg?hmac=uMSExsgG8_PWwP9he9Y0LQ4bFDLlij7voa9lU9KMXDE',
      title: 'title 4',
      subheading: 'Rank 4',
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy",
    },
  ];

  const renderCard = (card, index) => {
    return (
      <Card style={{ width: '18rem' }} key={index}>
        <Card.Img variant='top' src={card.image} />
        <Card.Body className='text-dark'>
          <Card.Title>{card.title}</Card.Title>
          <Card.Subtitle>{card.subheading}</Card.Subtitle>
          <Card.Text>{card.text} </Card.Text>
        </Card.Body>
      </Card>
    );
  };

  return <div>{cardInfo.map(renderCard)}</div>;
}

export default CardUi;

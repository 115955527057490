import React, { Component } from 'react';
import './loader.css';

const Loader = (props) => {
  console.log(props);
  return (
    <div style={{ height: props.height }}>
      <div className='loader-container ' style={{ padding: props.padding }}>
        <div style={{ backgroundColor: props.backgroundColor }}>
          <div className='loader-container '>
            <div className='myloader'>
              <div className='myloader--dot' />
              <div className='myloader--dot' />
              <div className='myloader--dot' />
              <div className='myloader--dot' />
              <div className='myloader--dot' />
              <div className='myloader--dot' />
              <div className='myloader--text' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Loader;

import React from 'react';
import JsonData from '../OverallPerformance.json';
import './CceTabel.css';
import {
  BrowserRouter as Router,
  Route,
  Link,
  BrowserRouter,
} from 'react-router-dom';

function OverallPerformance({ type, opData }) {
  console.log(opData);
  const DisplayData = opData.map((info) => {
    // {
    //   "state": "Sikkim",
    //   "district": "Gangtok",
    //   "udise_id": "6190ddd35b38a40d2428f2e3",
    //   "udise_code": "11040300801",
    //   "school_name": "TNSSS\r",
    //   "no_users": 190,
    //   "total_students": 187,
    //   "pct": 101.6043
    // },
    return (
      <>
        {type === 'sl' ? (
          <tr>
            {/* <td>{info.state}</td> */}
            {/* <td>{info.sl}</td> */}
            <td>{info.udise_code}</td>
            <td>{info.school_name}</td>
            <td>{info.no_users}</td>
            <td>{info.total_students}</td>
            <td>{Math.ceil(info.pct)}</td>
            <td>
              {' '}
              <Link to={'/performance-school/' + info.udise_id}>
                <a href={info.performance} target='blank'>
                  <span className='text-danger'>View Performance</span>
                </a>
              </Link>
            </td>
          </tr>
        ) : (
          <tr>
            <td>{info.subject}</td>
            <td>{info.Aspiring}</td>
            <td>{info.Scholar}</td>
            <td>{info.Master}</td>
          </tr>
        )}
      </>
    );
  });

  return (
    <div className='mar-topbot'>
      <div class='table-responsive'>
        <table stripped bordered hover responsive='sm'>
          <thead>
            {type === 'sl' ? (
              <tr>
                {/* <th>sl</th> */}
                <th>UDISE ID</th>
                <th>SCHOOL NAME</th>
                <th>NEEVE USERS</th>
                <th>ENROLLMENT</th>
                <th>%NEEVE USER</th>
                <th>SEE PERFORMANCE ON SCHOOL PAGE</th>
              </tr>
            ) : (
              <tr>
                <th>Subject</th>
                <th>Aspiring</th>
                <th>Scholar</th>
                <th>Master</th>
              </tr>
            )}
          </thead>
          <tbody>{DisplayData}</tbody>
        </table>
      </div>
    </div>
  );
}

export default OverallPerformance;

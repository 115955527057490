import React from 'react';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Header from './header-signedin';
import { useEffect, useState } from 'react';
import * as _ from 'lodash';
import LeftMenu from './left-menu';
import { useParams } from 'react-router';
import HeadingWhite from './HeadingWhite';
import './DistrictPerformance.css';
import EducationSikkim from './EducationSikkim';
import PerformanceSchoolTab from './PerformanceSchoolTab';
import Loader from './loader';
import { getDistDashboardData, getDistList } from '../services/api';

var timeout = 1000;

function DistrictPerformance() {
  const { state, dist } = useParams();

  const [distData, setDistData] = useState();
  const [newDist, setNewDist] = useState(dist);
  useEffect(() => {
    getDistDashboardData(state, newDist).then((res) => {
      setDistData(res.data.data);
    });
  }, [newDist]);

  console.log(state);
  console.log(dist);
  console.log(newDist);

  const getDivHeight = (id) => {
    let innerHeight = 0;
    let element = window; //document.getElementById(id);
    if (element !== null) {
      innerHeight = element.clientHeight;
    }
    return innerHeight;
  };
  return (
    <>
      <Header />
      <div className='container-fluid p-10 bg-grey'>
        {distData && (
          <div className='row min-height' style={{ padding: 20 }}>
            <div className='col-3 left-menu-mod'>
              <br />
              <LeftMenu />
            </div>
            <div className='col-9 ' id={'districtPerformance'}>
              <div className='row'>
                <div className='col-12 right-menu-mod '>
                  <div className='row'>
                    <div className='col-12'>
                      <div>
                        <h2 className='text-center text-indigo fw-bolder pt-3 '>
                          {state} Dashboard - District Level Performance
                        </h2>
                      </div>
                      <div className=' d-flex  justify-content-center pt-0 '>
                        <div className='d-inline-flex  w-90 mr-auto ml-auto flex-column   '>
                          <p className='text-center pt-0'>
                            This dashboard captures district level performance
                            of students. Select District in the panel below to
                            view its performance. Select Overall to see
                            performance or School list to see list of schools
                            and performance in a district.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-12 '>
                      <div className='sky-curve-both-sm'>
                        <div className=' mb-5 text-center '>
                          <div className='pad-top text-white'>
                            <HeadingWhite
                              className='text-white'
                              text='Real-time CCE Report - For Districts'
                            />
                          </div>
                          <div className='d-flex justify-content-center  '>
                            <div className=' w-95  '>
                              <div className='row'>
                                <div className='col-12'>
                                  <EducationSikkim
                                    setDistData={setDistData}
                                    state={state}
                                    dist={newDist}
                                    setNewDist={setNewDist}
                                    summery={distData.district_summary[0]}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className=' mb-5'>
                          <div className=' mb-5 text-center '>
                            <div className='d-flex justify-content-center  '>
                              <div className=' w-95  '>
                                <PerformanceSchoolTab
                                  state={state}
                                  dist={newDist}
                                  slData={distData.district_schools_details}
                                  opData={distData.district_cce_report}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {!distData && (
          <Loader
            height={getDivHeight('districtPerformance') - 162}
            padding={20}
            backgroundColor={'#FFFFFF'}
          />
        )}
      </div>
    </>
  );
}

export default DistrictPerformance;

import React from 'react';
import Carousel from 'react-elastic-carousel';
import { Card } from 'react-bootstrap';
import img2 from '../assets/img2.jpg';
import './PerformingCardSlider.css';

function PerformingCardSlider() {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 3 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 },
  ];

  const cardInfo = [
    {
      image: 'https://cdn.neeve.io/content/icon/Highlights%20-%20download.png',
      title: '55% more Neeve Users!!!',
      subheading: '',
      text: 'Exceeding the target of 7000 students, app Neeve reached a mark of 10,816 registered users. Neeve user database was upgraded to accommodate the enthusiastic activity of the students. All students of Sikkim are welcome on Neeve to experience world class education that is delivered in a fun way.',
    },
    {
      image: 'https://cdn.neeve.io/content/icon/Highlight%20Readiness.png',
      title: 'Readiness Sprint',
      subheading: '',
      text: 'Designed to revisit critical concepts before starting a new class, Neeve Readiness Sprint has started from April 2022 in 100 schools. Baseline Assessment of learning level was carried out in hybrid mode - both online and offline. For online assessments, the a detailed performance report was ready for the teachers - the very next day!!',
    },
    {
      image:
        'https://cdn.neeve.io/content/icon/Highlight%20-%20Teacher%20observation.png',
      title: 'Classroom Observation',
      subheading: '',
      text: '20 schools and 40 subject teachers were chosen for Classroom Observation, which is an integral part of LEP. The findings related to classroom settings, interactions, exchanges and exploration processes are noted. A report on best practices is on the way to share with all teachers and administrators to follow.',
    },
  ];

  const renderCard = (card, index) => {
    return (
      <Card key={index}>
        <Card.Img variant='top' src={card.image} />
        <Card.Body className='text-dark'>
          <Card.Title>{card.title}</Card.Title>
          <Card.Subtitle>{card.subheading}</Card.Subtitle>
          <Card.Text>{card.text} </Card.Text>
        </Card.Body>
      </Card>
    );
  };

  return (
    <Carousel
      itemPadding={[0, 8]}
      itemsToScroll={1}
      showArrows={false}
      breakPoints={breakPoints}
    >
      {cardInfo.map(renderCard)}
    </Carousel>
  );
}

export default PerformingCardSlider;

import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from './../assets/logo.png';
import Header from './header-signin';
import Footer from './footer';
import ForgotPassword from './forgotpassword';
import bgimage from './../assets/bg-image.png';
import { Link } from 'react-router-dom';
import axios from 'axios';
import * as mod from './../resources/url';
import { Redirect } from 'react-router';

function Login() {
  if (localStorage.getItem('is_done')) {
    return <Redirect to={'/resource/student-readiness'} />;
  }
  return (
    <div>
      <Header />
      <div className='singnupbg' style={{ backgroundImage: `url(${bgimage})` }}>
        <br />
        <br />
        <br />
        <br />

        <div className='container'>
          <div className='row text-center'>
            <div className='col-0 col-md-2'>
              <a></a>
            </div>

            <div className='col-0 col-md-8 formDiv'>
              <h2>Login</h2>
              <br />
              <NameForm />
            </div>

            <div className='col-0 col-md-2'>
              <a></a>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}

class NameForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { udise: '', password: '' };

    this.handleChangeUdise = this.handleChangeUdise.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeUdise(event) {
    this.setState({ udise: event.target.value });
  }

  handleChangePassword(event) {
    this.setState({ password: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    console.log(this.state.udise);
    console.log(this.state.password);
    var udise_ok = 0;
    var password_ok = 0;

    if (this.state.udise && this.state.udise != '') {
      console.log('UDISE Defined');
      document.getElementById('udise-error-1').style.display = 'none';
      udise_ok = 1;
    } else {
      document.getElementById('udise-error-1').style.display = 'block';
    }

    if (this.state.password && this.state.password != '') {
      console.log('password Defined');

      document.getElementById('password-error-1').style.display = 'none';
      password_ok = 1;
    } else {
      document.getElementById('password-error-1').style.display = 'block';
    }

    if (udise_ok === 1 && password_ok === 1) {
      console.log('All OK');
      var apiBaseUrl = mod.api_url + '/user/web/login';

      var data = {
        udiseId: this.state.udise,
        password: this.state.password,
      };
      console.log(data);
      localStorage.setItem('user_id', this.state.udise);
      axios
        .post(apiBaseUrl, data)
        .then(function (response) {
          console.log(response);

          if (response.status == 200) {
            localStorage.setItem(
              'u_code',
              encodeURIComponent(JSON.stringify(response.data.data.accessToken))
            );

            //localStorage.setItem('udise_id', encodeURIComponent(JSON.stringify(response.data.data.id)));
            localStorage.setItem('udise_id', response.data.data.id);
            localStorage.setItem('state', response.data.data.state);
            localStorage.setItem('district', response.data.data.district);
            localStorage.setItem('school_name', response.data.data.school_name);
            localStorage.setItem('role', response.data.data.role);

            //localStorage.setItem('school_name', true);

            localStorage.setItem('is_done', true);

            //alert(response.data.data.id);
            //alert(response.data.data.accessToken);

            //          window.location.href =
            //          response.data.data.role === "Admin"
            //              ? `/statedashboard/Sikkim`
            //              : `/resource`;

            if (response.data.data.role === 'Admin') {
              window.location.href = `/statedashboard/${response.data.data.state}`;
            } else window.location.href = `/resource`;

            console.log('Login successfull');
          } else {
            alert(response.data.message);
          }
        })
        .catch(function (error) {
          console.log(error);
          document.getElementById('password-error-1').style.display = 'block';
        });
    }
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <input
          className='input-text-box'
          type='text'
          name='udise'
          onChange={this.handleChangeUdise}
          placeholder='UDISE Code'
        />
        <h6 id='udise-error-1' className='error-msg'>
          <span>🠕</span> Please fill the UDISE Code
        </h6>

        <input
          className='input-text-box'
          type='password'
          name='password'
          onChange={this.handleChangePassword}
          placeholder='Password'
        />

        <h6 id='password-error-1' className='error-msg'>
          <span>🠕</span> Your user id or password is incorrect. Please enter
          your school udise code and account password
        </h6>

        <div className='more-info row text-right'>
          <div className='col'>
            <Link to='/forgotpassword'>Forgot Password?</Link>
          </div>
        </div>

        <input
          className='btn header-nav-btn-primary'
          type='submit'
          value='Submit'
        />
      </form>
    );
  }
}
export default Login;

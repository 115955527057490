import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from './../assets/logo.png';
import Header from './header-signup';
import Footer from './footer';
import bgimage from './../assets/bg-image.png';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ticklogo from './../assets/tick (3).svg';

function Login() {
  return (
    <div>
      <Header />
      <div className='singnupbg' style={{ backgroundImage: `url(${bgimage})` }}>
        <br />
        <br />
        <br />
        <br />

        <div className='container'>
          <div className='row text-center'>
            <div className='col-0 col-md-2'>
              <a></a>
            </div>

            <div className='col-0 col-md-8 formDiv'>
              <img src={ticklogo} style={{ width: '15%' }} />
              <h1>Thank you</h1>
              <h5>You have verified your school email</h5>

              <NameForm />
            </div>

            <div className='col-0 col-md-2'>
              <a></a>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}

class NameForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { udise: '', password: '' };

    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeEmail(event) {
    this.setState({ udise: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    window.location.href = `/forgotpassword_newpassword`;
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <input
          className='btn header-nav-btn-primary'
          type='submit'
          value='Continue'
        />
      </form>
    );
  }
}
export default Login;

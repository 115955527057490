import React from 'react';
import Carousel from 'react-elastic-carousel';
import { Card } from 'react-bootstrap';
import img2 from '../assets/img2.jpg';
import TecStuTestiM from '../TeacherStudentTestimonialM.json';
import './TeacherStudentTestimonial.css';
import { Image } from 'react-bootstrap/';

function TeacherStudentTestimonial() {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 3 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 },
  ];

  const renderCard = (card, index) => {
    return (
      <Card key={index} className='abc'>
        <Card.Img variant='top' src={card.img} />
        <Card.Body className='text-dark'>
          <Card.Title>{card.title}</Card.Title>
          <Card.Subtitle>{card.subheading}</Card.Subtitle>
          <Card.Text>{card.text} </Card.Text>
        </Card.Body>
      </Card>
    );
  };

  return (
    <Carousel
      itemPadding={[0, 8]}
      itemsToScroll={1}
      showArrows={false}
      breakPoints={breakPoints}
    >
      {TecStuTestiM.map(renderCard)}
    </Carousel>
  );
}

export default TeacherStudentTestimonial;

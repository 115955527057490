import React from 'react';

function Footer() {
  return (
    <div>
      <hr style={{ marginTop: '0px' }} />
      <div className='container'>
        <div className='row text-center'>
          <div className='col'>
            <h6>© 2021 Neeve - All Rights Reserved</h6>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;

import React from 'react';

import logo from './../assets/logo.png';
import './../custom.css';
import { Link } from 'react-router-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Login from './login';
import Signup from './signup';
import Home from './home';

function Header() {
  //const path = window.location.href;//this.props.location.pathname.slice(1);
  return (
    <div>
      <div className='container'>
        <div className='row text-center'>
          <div className='col-md-2 col-sm-12'>
            <Link to='/'>
              <img src={logo} />
            </Link>
          </div>
          <div
            className='col-md-8 col-sm-6 col-xs-6'
            style={{ alignSelf: 'center', textAlign: 'right' }}
          >
            <span className='header-nav-span'>New User?</span>
          </div>
          <div
            className='col-md-2 col-sm-6 col-xs-6'
            style={{ alignSelf: 'center', textAlign: 'right' }}
          >
            <Link
              className='header-nav-btn header-nav-btn-primary'
              style={{ marginRight: '0px' }}
              to='/signup'
            >
              Sign Up{' '}
            </Link>
          </div>
        </div>
      </div>
      <hr style={{ marginBottom: '0px' }} />
    </div>
  );
}
export default Header;

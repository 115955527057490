import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from './../assets/logo.png';
import Header from './header-signedin';
import Footer from './footer';
import bgimage from './../assets/dashboard.png';
import { Link } from 'react-router-dom';
import * as mod from './../resources/url';
import LeftMenu from './left-menu';
import { Redirect } from 'react-router';

function Login() {
  if (!localStorage.getItem('is_done')) {
    return <Redirect to={'/login'} />;
  }

  return (
    <div>
      <Header />
      <div className='bg-grey min-height'>
        <div className='container-fluid p-10'>
          <div className='row min-height' style={{ padding: 20 }}>
            <div className='col-2 left-menu'>
              <br />
              <LeftMenu />
            </div>
            <div className='col-9 right-menu'>
              <img src={bgimage} style={{ width: '100%' }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

class NameForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: '' };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleChangeEmail(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    alert('Last submitted Value: ' + this.state.value);
    event.preventDefault();
  }

  componentDidMount() {
    fetch(mod.api_url + `/user/udises`)
      .then((res) => res.json())
      .then((json) => this.setState({ schooldata: json }));
  }

  render() {
    if (!this.state.schooldata) {
      return null;
    }

    console.log(this.state.schooldata.data);

    return (
      <form onSubmit={this.handleSubmit}>
        <input
          className='input-text-box'
          type='text'
          name='udise'
          onChange={this.handleChange}
          placeholder='UDISE Code'
        />
        <br />

        <input
          className='input-text-box'
          type='text'
          name='schoolname'
          onChange={this.handleChange}
          placeholder='School Name'
          disabled
        />
        <br />
        <input
          className='input-text-box'
          type='password'
          name='retypepassword'
          onChange={this.handleChange}
          placeholder='Password'
        />

        <div className='more-info row text-right'>
          <div className='col'>
            <Link to='/'>Forgot Password?</Link>
          </div>
        </div>

        <input
          className='btn header-nav-btn-primary'
          type='submit'
          value='Submit'
        />
      </form>
    );
  }
}
export default Login;
